import { getters, mutations, actions as parentActions, state } from '~/sites/shop/store/user'
import eventBus from '@/events/eventBus'
import eventDefinitions from '@/events/eventDefinitions'
import jsCookie from 'js-cookie'

const actions = {
  ...parentActions,
  async FETCH_USER({ commit, state, dispatch, rootState }, { forceReload = false }) {
    if (!state.isLoaded || forceReload) {
      commit('SET_LOADING', true)
      try {
        const response = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/user', {})
        commit('SET_USER_DATA', response.data)
        eventBus.$emit(eventDefinitions.USER.LOADED, { user: state.userData })
        await dispatch('auth/SET_VARIANT', state.userData.variantHash, { root: true })
        await dispatch('auth/SET_USER_GROUPS', state.userData.groups, { root: true })
        dispatch('FETCH_WISHLIST')
        dispatch('FETCH_USER_SHOPPING_LISTS')

        // Set EUR currency for user with main address set to Slovakia
        if (response.data?.address?.countryAlpha2 === 'SK' || response.data?.address?.countryCode === 'SVK') {
          jsCookie.set('X-Inspishop-Currency', 'EUR')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          dispatch('auth/REMOVE_AUTH', null, { root: true })
          dispatch('auth/SET_AXIOS_TOKEN', null, { root: true })
        } else {
          console.error(e)
        }
      }
      commit('SET_LOADING', false)
    }
  },
}

export { actions, getters, mutations, state }
