import Dropdown from '@theme/components/utils/Dropdown'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  components: {
    Dropdown,
    Loader,
  },
  props: {
    id: String,
    dropdown: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.CurrencySwitcher.dropdown
      },
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currencies: null,
    }
  },
  computed: {
    dropdownId() {
      return this.id || 'CurrencySwitcher'
    },
  },
  mounted() {
    if (!this.dropdown) this.loadCurrencies()
  },
  methods: {
    formatCurrencyName(currency) {
      return this.$themeSettings.components.CurrencySwitcher.displayNameType === 'name'
        ? currency.name
        : this.$themeSettings.components.CurrencySwitcher.displayNameType === 'symbol'
          ? currency.symbol
          : currency.code
    },
    async loadCurrencies() {
      this.startLoading()
      await this.$store.dispatch('currency/FETCH_CURRENCIES')
      this.stopLoading()
    },
    selectCurrency(currency) {
      this.$store.dispatch('currency/CHANGE_CURRENCY', currency)
    },
  },
}
