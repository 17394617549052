import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import _ from 'lodash'
import gql from 'graphql-tag'

export default {
  mixins: [ImageUrlMixin],
  props: {
    isActive: false,
    product: Object,
  },
  data() {
    return {
      color: null,
      image: null,
    }
  },
  computed: {
    backgroundImage() {
      return this.imageUrl({ src: this.image, width: 64, height: 64, crop: true })
    },
    getColorParameterEntityId() {
      return this.$contentSettings.colorParameterId
    },
  },
  async mounted() {
    if (this.product.content) {
      const colorParameter = _.find(
        this.product.content.parameters,
        parameter => parameter.entityId === this.getColorParameterEntityId,
      )
      if (colorParameter && colorParameter.values.length > 0) {
        const result = await this.$apollo.query({
          query: gql`
            query($id: String!) {
              color(filters: { _id: [$id] }) {
                entities {
                  _id
                  color_hex
                  parameter_value_image {
                    path
                  }
                }
              }
            }
          `,
          variables: {
            id: colorParameter.values[0],
          },
        })
        if (_.get(result, 'data.color.entities[0].color_hex')) {
          this.color = result.data.color.entities[0].color_hex
        }
        if (_.get(result, 'data.color.entities[0].parameter_value_image[0].path')) {
          this.image = result.data.color.entities[0].parameter_value_image[0].path
        }
      }
    }
  },
}
