import {
  actions as parentActions,
  getters as parentGetters,
  mutations as parentMutations,
  state as parentState,
} from '~/sites/shop/store/cart'
import _ from 'lodash'

const state = () => {
  return {
    ...parentState(),
    preserveCountry: false,
  }
}

const mutations = {
  ...parentMutations,
  PRESERVE_COUNTRY(state, value) {
    state.preserveCountry = value
  },
}

const actions = {
  ...parentActions,
  async SET_CART_INPUT_DEFAULT({ commit, dispatch, state, rootState, rootGetters }) {
    commit('SET_CART_INPUT_FIELD', {
      field: 'formalTitle',
      value: _.get(rootState, 'user.userData.attributes.custom.formalTitle', null),
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'dateOfBirth',
      value: _.get(rootState, 'user.userData.attributes.custom.dateOfBirth', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'fullName', value: state.cartData.customer.name })
    commit('SET_CART_INPUT_FIELD', { field: 'firstName', value: state.cartData.customer.firstName })
    commit('SET_CART_INPUT_FIELD', { field: 'lastName', value: state.cartData.customer.lastName })
    commit('SET_CART_INPUT_FIELD', { field: 'companyName', value: '' })
    commit('SET_CART_INPUT_FIELD', { field: 'email', value: state.cartData.customer.email })
    commit('SET_CART_INPUT_FIELD', { field: 'phone', value: state.cartData.customer.phone })
    commit('SET_CART_INPUT_FIELD', {
      field: 'isBusiness',
      value: this.$themeSettings.global.billingInputsAlwaysRequired ? true : !!state.cartData.customer.tin,
    })
    commit('SET_CART_INPUT_FIELD', {
      field: 'newsletter',
      value:
        _.get(rootState, 'user.isLoaded', null) && _.get(state, 'cartData.customer.name', null) !== ''
          ? state.cartData.customer.newsletter
          : this.$themeSettings.components.PageCheckoutInfo.newsletter.defaultValue,
    })
    if (state.cartData.customer.businessName) {
      commit('SET_CART_INPUT_FIELD', { field: 'businessName', value: state.cartData.customer.businessName })
    }
    commit('SET_CART_INPUT_FIELD', { field: 'businessTerms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'terms', value: false })
    commit('SET_CART_INPUT_FIELD', { field: 'tin', value: state.cartData.customer.tin })
    commit('SET_CART_INPUT_FIELD', { field: 'vatin', value: state.cartData.customer.vatin })
    commit('SET_CART_INPUT_FIELD', {
      field: 'vatinsvk',
      value: _.get(rootState, 'user.userData.attributes.custom.ICDPH', null),
    })
    commit('SET_CART_INPUT_FIELD', { field: 'street', value: state.cartData.shippingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'city', value: state.cartData.shippingAddress.city })

    if (!state.preserveCountry) {
      if (rootGetters['user/userIsLoggedIn']) {
        commit('SET_CART_INPUT_FIELD', {
          field: 'country',
          value: _.get(rootState, 'user.userData.address.countryCode', null),
        })
      } else {
        const countryCode = rootState.globals.countries.find(
          country =>
            country.languageCode ===
            rootState.globals.languages.find(language => language.code === this.$i18n.locale).code,
        )
        if (countryCode) {
          commit('SET_CART_INPUT_FIELD', {
            field: 'country',
            value: countryCode.alpha3,
          })
        }
      }
    }
    commit('SET_CART_INPUT_FIELD', { field: 'postcode', value: state.cartData.shippingAddress.postcode })
    commit('SET_CART_INPUT_FIELD', { field: 'differentAddress', value: state.cartData.hasCustomShippingAddress })
    commit('SET_CART_INPUT_FIELD', { field: 'note', value: state.cartData.comment })
    commit('SET_CART_INPUT_FIELD', {
      field: 'shippingBusinessName',
      value: state.cartData.customer.shippingBusinessName,
    })
    commit('SET_CART_INPUT_FIELD', { field: 'billingStreet', value: state.cartData.billingAddress.street })
    commit('SET_CART_INPUT_FIELD', { field: 'billingCity', value: state.cartData.billingAddress.city })
    commit('SET_CART_INPUT_FIELD', { field: 'billingPostcode', value: state.cartData.billingAddress.postcode })
    if (!state.preserveCountry) {
      commit('SET_CART_INPUT_FIELD', { field: 'billingCountry', value: state.cartData.billingAddress.countryCode })
    }
    dispatch('CHECK_FILLED_BILLING_DATA')
    commit('PRESERVE_COUNTRY', false)
  },
}

const getters = {
  ...parentGetters,
  isShipperValidForCountry: state => {
    const countryCode = state.input.country
    const shipper = state.input.shipper
    return shipper && shipper.availableCountriesCodes.includes(countryCode)
  },
  isCurrencyValidForCountry: (state, getters, rootState) => {
    const countryCode = state.input.country
    const currencyCode = rootState.currency.activeCurrency.code

    const currencyMap = {
      CZE: 'CZK',
      SVK: 'EUR',
    }

    return currencyMap[countryCode] === currencyCode
  },
}

export { actions, getters, mutations, state }
