import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.USER.LOADED, ({ user }) => {
  if (user) {
    window.dataLayer.push({
      email: user.email,
      event: 'user.loaded.purity',
      user_id: user.id,
      user_groups: user.groups,
      user_type: 'MO',
      user_country: user.address.countryAlpha2,
    })
  }
})
