import AppLink from '@theme/components/atom/AppLink'
import CartInfoDropDownContent from '@theme/components/cart/CartInfoDropDownContent'
import CurrencySwitcher from '@theme/components/shop/CurrencySwitcher'
import DropdownLinks from '@theme/components/utils/DropdownLinks'
import HeaderMain from '~/themes/base/components/shop/HeaderMain/HeaderMain'
import LogoutButton from '@theme/components/user/LogoutButton'

export default {
  extends: HeaderMain,
  components: {
    AppLink,
    CartInfoDropDownContent,
    CurrencySwitcher,
    DropdownLinks,
    LogoutButton,
  },
  computed: {
    userMenu() {
      return this.$store.state.globals.userMenu
    },
  },
  methods: {
    toggleSearch(e) {
      e.preventDefault()
      this.$store.state.search.headerSearchInputOpen
        ? this.$store.commit('search/CLOSE_HEADER_SEARCH_INPUT')
        : this.$store.commit('search/OPEN_HEADER_SEARCH_INPUT')
    },
  },
}
