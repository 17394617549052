export default function({ app }) {
  const cookieBotUrl = 'https://consent.cookiebot.com/uc.js'
  app.head.script.push({
    src: cookieBotUrl,
    async: true,
    ['data-cbid']: 'a15dcec1-4b95-479d-a27c-921ed8316bb3',
    ['data-blockingmode']: 'auto',
    type: 'text/javascript',
    id: 'Cookiebot',
  })
}
