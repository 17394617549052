import gql from 'graphql-tag'

export const actions = {
  async LOAD_CERTIFICATIONS({ commit, dispatch }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const certifications = await client.query({
        query: gql`
          query {
            certification {
              entities {
                parameter_value_image {
                  alt
                  path
                  title
                }
                link_url
                title
                weight
              }
            }
          }
        `,
      })
      return certifications.data.certification.entities
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
