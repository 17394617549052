import { isObject } from 'lodash'
import cookie from 'cookie'

export const strict = false

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { app, req, res, store, route }) {
    // Get settings
    const { currencyFromUrl, currencyFromIp } = app.$contentSettings.functions || {}
    let currencyToSetFromIp = null

    if (currencyFromIp && currencyFromIp.enable) {
      let ipInfo
      const ip =
        (req.headers['x-forwarded-for'] || '').split(',')[0] ||
        req.connection.remoteAddress ||
        req.socket.remoteAddress ||
        req.connection.socket.remoteAddress

      try {
        ipInfo = await dispatch('getIPAddressInfo', { ip })
      } catch (e) {
        if ('$bugsnag' in app) {
          app.$bugsnag.notify(e)
        } else {
          console.log(e)
        }
      }

      if (isObject(ipInfo) && 'country' in ipInfo && ipInfo.country !== 'CZ') {
        currencyToSetFromIp = 'EUR'
      }
    }

    // Set locale for SSR
    app.$axios.defaults.headers.common['locale'] = app.i18n.locale
    app.$axios.defaults.headers.common['x-inspishop-default-locale'] = app.$env.DEFAULT_LOCALE

    // Set domain
    app.$axios.defaults.headers.common['x-inspishop-domain'] = app.$env.DOMAIN_CODE

    store.commit('globals/SET_USER_AGENT', req.headers['user-agent'])
    store.commit('globals/SET_HOST', req.headers['host'])

    const cookies = (req.headers.cookie && cookie.parse(req.headers.cookie)) || {}
    const currencyFromCookie = cookies['X-Inspishop-Currency']

    const displayVATSettingFromCookie = cookies['X-Inspishop-DisplayVAT']
    if (displayVATSettingFromCookie) {
      store.commit('currency/SET_DISPLAY_VAT', displayVATSettingFromCookie === 'true')
    }

    const displayBothPricesSettingFromCookie = cookies['X-Inspishop-DisplayBothPrices']
    if (displayBothPricesSettingFromCookie) {
      store.commit('currency/SET_DISPLAY_BOTH_PRICES', displayBothPricesSettingFromCookie === 'true')
    }

    const currencyFromIP = currencyFromIp && currencyFromIp.enable && currencyToSetFromIp
    const currencyFromURL =
      currencyFromUrl && currencyFromUrl.enable && currencyFromUrl.query && route.query[currencyFromUrl.query]
        ? route.query[currencyFromUrl.query].toUpperCase()
        : false

    // Set active currency from query parameter of URL
    if (currencyFromURL) {
      app.$axios.defaults.headers.common['x-inspishop-currency'] = currencyFromURL
      res.cookie('X-Inspishop-Currency', currencyFromURL)
    }
    // Set active currency based on IP location of the request
    else if (currencyFromIP) {
      app.$axios.defaults.headers.common['x-inspishop-currency'] = currencyToSetFromIp
    }
    // Set active currency for initial fetch of global values
    else if (currencyFromCookie) {
      app.$axios.defaults.headers.common['x-inspishop-currency'] = currencyFromCookie
    }
    // Set token if exist user cookie
    if (cookies['X-Inspishop-User-Token']) {
      app.$axios.setToken(cookies['X-Inspishop-User-Token'], 'Bearer')
    }

    // Fetch store globals and set http headers for future requests
    await store.dispatch('globals/FETCH_GLOBALS_STORE')

    // Set translation variant for SSR
    app.$axios.defaults.headers.common['x-inspishop-translation-variant'] = store.state.locale.translationVariant || ''

    // Set user variant and groups to store
    if (cookies['X-Inspishop-User-Variant']) {
      await store.dispatch('auth/SET_VARIANT', cookies['X-Inspishop-User-Variant'])
    }
    if (cookies['X-Inspishop-User-Groups']) {
      await store.commit('auth/SET_USER_GROUPS', cookies['X-Inspishop-User-Groups'].split(','))
    }

    // Set active currency for SSR, includes default value from globals if not set in cookie
    app.$axios.defaults.headers.common['x-inspishop-currency'] = store.state.currency.activeCurrency.code

    // Set user-variant and groups for SSR
    app.$axios.defaults.headers.common['x-inspishop-user-variant'] = store.state.auth.variant
    app.$axios.defaults.headers.common['x-inspishop-user-groups'] = store.state.auth.groups
      ? store.state.auth.groups.join(',')
      : ''

    // Fetch addition globals with http headers set
    const promises = [
      store.dispatch('globals/FETCH_COUNTRIES'),
      store.dispatch('globals/FETCH_GLOBALS_CMS'),
      store.dispatch('globals/FETCH_SLUG_METADATA'),
      store.dispatch('globals/FETCH_SHIPPERS'),
      store.dispatch('menu/FETCH_MENU', 'mainMenu'),
      store.dispatch('menu/FETCH_MENU', 'menu_entry'),
      store.dispatch('menu/FETCH_MENU', 'header_top_1'),
      store.dispatch('menu/FETCH_MENU', 'footer_main_1'),
      store.dispatch('menu/FETCH_MENU', 'footer_main_2'),
      store.dispatch('menu/FETCH_MENU', 'footer_main_3'),
      store.dispatch('menu/FETCH_MENU', 'footer_main_4'),
    ]
    await Promise.all(promises)
  },

  async getIPAddressInfo(_, { ip }) {
    return await this.$axios.$get(`https://api.country.is/${ip}`)
  },
}
