import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import itemTransformer from './transformers/item.transformer'

eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
  const items = order.items.filter(
    item => !(item.attributes && item.attributes.discount) && item.shoppable && item.product,
  )
  window.dataLayer.push({
    cart_id: order.attributes.cartId,
    event: eventDefinitions.ORDER.PLACED,
    order_id: order.id,
    order_items: items.map(item => itemTransformer(item)),
    order_prices_totalWithTax: order.prices.totalWithTax,
    order_prices_totalWithoutTax: order.prices.totalWithoutTax,
    order_prices_itemsWithTax: order.prices.itemsWithTax,
    order_prices_itemsWithoutTax: order.prices.itemsWithoutTax,
    order_productCodes: items.map(item => item.product?.marketingCode),
    order_variantCodes: items.map(item => item.shoppable?.marketingCode),
    totalAmount: items.reduce((acc, item) => acc + item.amount, 0),
    currencyCode: order.currencyCode,
    customer_name: order.customer.name,
    customer_email: order.customer.shippingEmail,
    customer_address: order.shippingAddress.fullAddress,
  })
})
