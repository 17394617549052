import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  catalog: {
    defaultSortType: 'MostSoldSort',
  },
  global: {
    showAvailability: false,
  },
  components: {
    ArticleBox: {
      articleInfo: {
        enable: true,
      },
      image: {
        height: 265, // Change SASSs variable $ArticleBox-image-height
        position: 'left', // top, left
        width: 464, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'primary', // link, primary or secondary
        enable: true,
      },
      showIcons: true,
    },
    ArticleList: {
      perLine: {
        md: 1,
        lg: 1,
      },
      perPage: 15,
    },
    CatalogSorting: {
      options: ['MostSoldSort', 'PriceSortASC', 'PriceSortDESC'], // DefaultSort, MostSoldSort, PriceSortASC, PriceSortDESC, NameSortASC, NameSortDESC
    },
    CatalogSubCategories: {
      countPerLine: {
        xl: 3,
      },
      fullViewportWidthWrapper: true,
      image: {
        height: 40,
        width: 40,
      },
      showHeading: true,
    },
    CheckoutMethodsShipper: {
      showDeliveryDate: false,
    },
    ContentHeader: {
      image: {
        enableAtBreakpoint: 'md',
        height: 350,
        width: 3840,
      },
    },
    CurrencySwitcher: {
      enable: true,
    },
    FeaturedArticles: {
      image: {
        position: 'left', // top, left
      },
      perLine: {
        md: 1,
        lg: 1,
      },
    },
    FooterBottom: {
      paymentMethods: {
        enable: false,
      },
      showContact: false,
      showCopyright: false,
    },
    FooterContact: {
      email: {
        icon: 'mail',
      },
      showSocialNetworkLinks: true,
    },
    FooterMain: {
      showContact: true,
      showSocialNetworkLinks: false,
    },
    MainMenuDropdownContent: {
      content: {
        icon: 'right-chevron', // Visible for type `link` only
        type: 'link', // SubCategoryBox, link
      },
      showHeading: false,
      subcategoriesCountPerLine: {
        md: 3,
        lg: 4,
        xl: 5,
      },
    },
    MainMenuMobileFooter: {
      contact: {
        showBranchesLink: false,
      },
    },
    MenuHorizontal: {
      fullViewportWidthWrapper: true,
      showHeading: true,
    },
    Newsletter: {
      alignToCenter: false,
    },
    PageAboutPurchase: {
      showAfterPurchase: false,
    },
    PageArticleDetail: {
      articleInfo: {
        enable: true,
      },
      backToArticles: {
        enable: true,
        button: 'primary-outline', // link, primary or secondary
      },
      header: {
        enable: true,
      },
      latestArticles: {
        count: 3,
      },
      perex: {
        enable: false,
      },
      sidebar: {
        enable: true,
      },
    },
    PageArticleList: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: true, // Requires header enabled!
      },
    },
    PageArticleListCategory: {
      header: {
        enable: true,
      },
      menuHorizontal: {
        enable: true, // Requires header enabled!
      },
    },
    PageCatalog: {
      Pagination: {
        bottom: {
          showButtonNextToPagination: true,
        },
        button: {
          style: 'primary-outline',
        },
      },
      perLine: {
        lg: 3,
        xl: 4,
      },
      perPage: 24,
      showCatalogHeader: true,
      showCategoryMenuInSidebar: false,
      showProductsRecommended: false,
      subCategories: {
        useContainerComponent: true,
      },
    },
    PageCheckoutItems: {
      showProductsRecommended: false,
      showPromoCodeInputOnPageLoad: true,
      showTotalPriceWithoutTax: false,
      topCategories: {
        fullViewportWidthWrapper: true,
      },
    },
    PageHomepage: {
      featuredArticles: {
        enable: false,
      },
      mainHeadingPosition: 'belowSlider', // aboveSlider, belowSlider
      showHomepageBranches: false,
      showProductsRecommended: false,
      showVisitedProducts: false,
      showUniqueSellingPoints: false,
      topCategories: {
        fullViewportWidthWrapper: true,
      },
    },
    PageNotFound: {
      showProductsRecommended: false,
      topCategories: {
        fullViewportWidthWrapper: true,
      },
    },
    PageInternalError: {
      showProductsRecommended: false,
      topCategories: {
        fullViewportWidthWrapper: true,
      },
    },
    PageProductDetail: {
      reviews: {
        enable: true,
        initialCount: 5,
        onlyWithReview: 0,
      },
      showAlternativeProducts: true,
      showAvailability: false,
      showAvailabilityInWarehouses: false,
      showDeliveryOptions: false,
      showProductReviews: true,
      showProductsRecommended: false,
    },
    PageTextPage: {
      centerContent: true, // Works if sidebar is disabled
      sidebar: {
        enable: false,
      },
    },
    ProductBox: {
      badge: {
        color: 'yellow', // see tags color palette + primary color
      },
      contentOrder: {
        image: 0,
        name: 1,
        category: 2,
        availability: 3,
        description: 4,
        variants: 5,
        price: 6,
        colorVariants: 7,
      },
      imageWidth: 222,
      imageHeight: 260,
      enableSwitchVariant: true,
      showAvailability: false,
    },
    ProductBoxVariants: {
      enableActiveClass: true,
      visibleOnEvent: 'load', // load, hover
    },
    ProductDetailImages: {
      image: {
        height: 480,
        width: 680,
      },
    },
    ProductsVisited: {
      align: 'left', // default, left
      component: 'ProductBox', // ProductTeaser, ProductBox
    },
    SearchInput: {
      label: {
        icon: '',
      },
      button: {
        icon: 'search',
      },
    },
    Slider: {
      cmsTextContent: {
        enable: true,
      },
      image: {
        width: 1400, // Change SASSs variable $Slider-image-width
        height: 400, // Change SASSs variable $Slider-image-height
      },
      showCirclePagination: true,
      showNumericPaginationOnMobile: false,
      showTabs: false, // If false, truncate SASS variable $Slider-with-tabs-breakpoints
      showTimerIndicator: false,
    },
    SocialNetworkLinks: {
      showLabel: false,
      showNetworkName: false,
    },
    TopCategories: {
      perLine: {
        sm: 2,
        md: 4,
      },
    },
    TopCategory: {
      image: {
        crop: true,
        height: 300,
        width: 400,
      },
    },
    UniqueSellingPoints: {
      enable: false,
    },
    VerticalMenuItem: {
      iconClose: 'plus',
      iconOpen: 'minus',
    },
  },
}

export default merge(themeSettings, overloadSettings)
