import AppButton from '@theme/components/atom/AppButton'
import AppNumber from '@theme/components/atom/AppNumber'
import Modal from '@theme/components/utils/Modal'
import _ from 'lodash'

export default {
  components: { AppButton, AppNumber, Modal },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true,
    },
    inputClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  created: function() {
    this.debouncedUpdate = _.debounce(this.updateBackend, 500)
  },
  methods: {
    async updateBackend(value) {
      if (!this.loading) {
        if (value === 0) {
          this.$refs.modal.show()
        } else {
          this.loading = true
          await this.$store.dispatch('cart/UPDATE_ITEM', {
            id: this.item.id,
            quantity: value,
          })
          const cartItem = _.find(this.$store.state.cart.cartData.items, { id: this.item.id })
          if (cartItem) {
            this.value = Number(cartItem.amount.toFixed(this.decimalPosition))
          }
          this.loading = false
        }
      }
    },
    async removeItem() {
      this.loading = true
      await this.$store.dispatch('cart/REMOVE_FROM_CART', this.item)
      this.loading = false
    },
    cancelRemove() {
      this.$refs.numberInput.value = this.item.amount
    },
  },
  computed: {
    decimalPosition() {
      return this.metersParameter ? this.$themeSettings.components.ProductMetersCalculator.decimalPosition || 0 : 0
    },
    metersParameter() {
      const parameters = _.get(this.item, 'variant.content.parameters') || []
      return parameters.find(parameter => parameter.entityId === this.$contentSettings.metersParameterId)
    },
  },
}
