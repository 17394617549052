import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07edc0b3 = () => interopDefault(import('../sites/purityvision/pages/user/activate.vue' /* webpackChunkName: "sites/purityvision/pages/user/activate" */))
const _74ade12c = () => interopDefault(import('../sites/purityvision/pages/blocksettings.vue' /* webpackChunkName: "sites/purityvision/pages/blocksettings" */))
const _371d6db3 = () => interopDefault(import('../sites/purityvision/pages/articleList/index.vue' /* webpackChunkName: "sites/purityvision/pages/articleList/index" */))
const _3877ca4e = () => interopDefault(import('../sites/purityvision/pages/branch/index.vue' /* webpackChunkName: "sites/purityvision/pages/branch/index" */))
const _4ee9294e = () => interopDefault(import('../sites/purityvision/pages/index.vue' /* webpackChunkName: "sites/purityvision/pages/index" */))
const _085b8b5f = () => interopDefault(import('../sites/purityvision/pages/search/index.vue' /* webpackChunkName: "sites/purityvision/pages/search/index" */))
const _8cbb73c2 = () => interopDefault(import('../sites/purityvision/pages/contact/index.vue' /* webpackChunkName: "sites/purityvision/pages/contact/index" */))
const _09e44e7d = () => interopDefault(import('../sites/purityvision/pages/checkout/index.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/index" */))
const _46f60395 = () => interopDefault(import('../sites/purityvision/pages/faq/index.vue' /* webpackChunkName: "sites/purityvision/pages/faq/index" */))
const _39ab8873 = () => interopDefault(import('../sites/purityvision/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/purityvision/pages/shoppingGuide/index" */))
const _0f90d2e1 = () => interopDefault(import('../sites/purityvision/pages/compare.vue' /* webpackChunkName: "sites/purityvision/pages/compare" */))
const _1890cc65 = () => interopDefault(import('../sites/purityvision/pages/login.vue' /* webpackChunkName: "sites/purityvision/pages/login" */))
const _78aab657 = () => interopDefault(import('../sites/purityvision/pages/register.vue' /* webpackChunkName: "sites/purityvision/pages/register" */))
const _7e2cd220 = () => interopDefault(import('../sites/purityvision/pages/passwordReset.vue' /* webpackChunkName: "sites/purityvision/pages/passwordReset" */))
const _a542939a = () => interopDefault(import('../sites/purityvision/pages/sitemapconfig.vue' /* webpackChunkName: "sites/purityvision/pages/sitemapconfig" */))
const _427e3c23 = () => interopDefault(import('../sites/purityvision/pages/term/index.vue' /* webpackChunkName: "sites/purityvision/pages/term/index" */))
const _ec279fe2 = () => interopDefault(import('../sites/purityvision/pages/maintenance.vue' /* webpackChunkName: "sites/purityvision/pages/maintenance" */))
const _980f0e7c = () => interopDefault(import('../sites/purityvision/pages/user/index.vue' /* webpackChunkName: "sites/purityvision/pages/user/index" */))
const _b2d305a6 = () => interopDefault(import('../sites/purityvision/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/purityvision/pages/aboutPurchase/index" */))
const _38d2d48a = () => interopDefault(import('../sites/purityvision/pages/passwordForgotten.vue' /* webpackChunkName: "sites/purityvision/pages/passwordForgotten" */))
const _902ffc74 = () => interopDefault(import('../sites/purityvision/pages/brand/index.vue' /* webpackChunkName: "sites/purityvision/pages/brand/index" */))
const _bc32240a = () => interopDefault(import('../sites/purityvision/pages/auth/proxy.vue' /* webpackChunkName: "sites/purityvision/pages/auth/proxy" */))
const _18b8d45a = () => interopDefault(import('../sites/purityvision/pages/checkout/info.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/info" */))
const _5b303dd2 = () => interopDefault(import('../sites/purityvision/pages/checkout/finished.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/finished" */))
const _4a936ec6 = () => interopDefault(import('../sites/purityvision/pages/checkout/methods.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/methods" */))
const _b5089bd2 = () => interopDefault(import('../sites/purityvision/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/importOrder/index" */))
const _bd628b80 = () => interopDefault(import('../sites/purityvision/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/purityvision/pages/user/shoppingLists/index" */))
const _c14df330 = () => interopDefault(import('../sites/purityvision/pages/user/orders/index.vue' /* webpackChunkName: "sites/purityvision/pages/user/orders/index" */))
const _7d9cf9ac = () => interopDefault(import('../sites/purityvision/pages/user/favourites.vue' /* webpackChunkName: "sites/purityvision/pages/user/favourites" */))
const _6efd8fe3 = () => interopDefault(import('../sites/purityvision/pages/user/settings.vue' /* webpackChunkName: "sites/purityvision/pages/user/settings" */))
const _6daa9d84 = () => interopDefault(import('../sites/purityvision/pages/user/dashboard.vue' /* webpackChunkName: "sites/purityvision/pages/user/dashboard" */))
const _b00836bc = () => interopDefault(import('../sites/purityvision/pages/user/addressList.vue' /* webpackChunkName: "sites/purityvision/pages/user/addressList" */))
const _70f0925b = () => interopDefault(import('../sites/purityvision/pages/user/password.vue' /* webpackChunkName: "sites/purityvision/pages/user/password" */))
const _3db9bbd3 = () => interopDefault(import('../sites/purityvision/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/purityvision/pages/checkout/payment/callback" */))
const _627838ee = () => interopDefault(import('../sites/purityvision/pages/entry.vue' /* webpackChunkName: "sites/purityvision/pages/entry" */))
const _6c7366b0 = () => interopDefault(import('../sites/purityvision/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/purityvision/pages/user/shoppingLists/_id" */))
const _0ac582d0 = () => interopDefault(import('../sites/purityvision/pages/user/orders/_id.vue' /* webpackChunkName: "sites/purityvision/pages/user/orders/_id" */))
const _67b4663a = () => interopDefault(import('../sites/purityvision/pages/author/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/author/_slug" */))
const _ea6e8ba6 = () => interopDefault(import('../sites/purityvision/pages/article/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/article/_slug" */))
const _3566cc6b = () => interopDefault(import('../sites/purityvision/pages/articleList/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/articleList/_slug" */))
const _3be50cde = () => interopDefault(import('../sites/purityvision/pages/branch/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/branch/_slug" */))
const _537cd6e6 = () => interopDefault(import('../sites/purityvision/pages/product/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/product/_slug" */))
const _40c79adb = () => interopDefault(import('../sites/purityvision/pages/term/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/term/_slug" */))
const _037ca93e = () => interopDefault(import('../sites/purityvision/pages/page/_slug.vue' /* webpackChunkName: "sites/purityvision/pages/page/_slug" */))
const _e9a6f74a = () => interopDefault(import('../sites/purityvision/pages/_.vue' /* webpackChunkName: "sites/purityvision/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _07edc0b3,
    name: "user-activate___cs"
  }, {
    path: "/blocksettings",
    component: _74ade12c,
    name: "blocksettings___cs"
  }, {
    path: "/clanky",
    component: _371d6db3,
    name: "articleList___cs"
  }, {
    path: "/clanky",
    component: _3877ca4e,
    name: "branch___cs"
  }, {
    path: "/eshop",
    component: _4ee9294e,
    name: "eshop___cs"
  }, {
    path: "/hledat",
    component: _085b8b5f,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _8cbb73c2,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _09e44e7d,
    name: "checkout___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _46f60395,
    name: "faq___cs"
  }, {
    path: "/poradna",
    component: _39ab8873,
    name: "shoppingGuide___cs"
  }, {
    path: "/porovnat",
    component: _0f90d2e1,
    name: "compare___cs"
  }, {
    path: "/prihlasit",
    component: _1890cc65,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _78aab657,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _7e2cd220,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _a542939a,
    name: "sitemapconfig___cs"
  }, {
    path: "/slovnik",
    component: _427e3c23,
    name: "term___cs"
  }, {
    path: "/udrzba",
    component: _ec279fe2,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _980f0e7c,
    name: "user___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _b2d305a6,
    name: "aboutPurchase___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _38d2d48a,
    name: "passwordForgotten___cs"
  }, {
    path: "/znacky",
    component: _902ffc74,
    name: "brand___cs"
  }, {
    path: "/auth/proxy",
    component: _bc32240a,
    name: "auth-proxy___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _18b8d45a,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _5b303dd2,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _4a936ec6,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _b5089bd2,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _bd628b80,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _c14df330,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/oblibene",
    component: _7d9cf9ac,
    name: "user-favourites___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _6efd8fe3,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _6daa9d84,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _b00836bc,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _70f0925b,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _3db9bbd3,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _627838ee,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _6c7366b0,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _0ac582d0,
    name: "user-orders-id___cs"
  }, {
    path: "/autori/:slug",
    component: _67b4663a,
    name: "author-slug___cs"
  }, {
    path: "/clanek/:slug",
    component: _ea6e8ba6,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _3566cc6b,
    name: "articleList-slug___cs"
  }, {
    path: "/pobocky/:slug",
    component: _3be50cde,
    name: "branch-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _537cd6e6,
    name: "product-slug___cs"
  }, {
    path: "/slovnik/:slug",
    component: _40c79adb,
    name: "term-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _037ca93e,
    name: "page-slug___cs"
  }, {
    path: "/*",
    component: _e9a6f74a,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
