import ProductReviewMixin from '~/mixins/ProductReviewMixin'

export default {
  mixins: [ProductReviewMixin],
  props: {
    fixedPoints: {
      type: Number,
      default: 1,
    },
    increment: {
      type: Number,
      default: 0.01,
    },
    itemSize: {
      type: Number,
      default: 30,
    },
    maxRating: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showRating: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
      validator: value => {
        return 0 <= value <= 100
      },
    },
  },
  data() {
    return {
      maxRatingProp: null,
      starRatingComponent: null,
    }
  },
  mounted() {
    this.maxRatingProp = this.maxRating ? this.maxRating : this.$themeSettings.components.StarRating.starsCount
    import('vue-rate-it').then(component => {
      this.starRatingComponent = component.StarRating
    })
  },
  methods: {
    emitInput(value) {
      this.$emit('input', this.transformStarsToRating(value))
    },
  },
  computed: {
    valueToStars() {
      return this.transformRatingToStars(this.value)
    },
  },
}
