import { hydrateWhenVisible } from 'vue-lazy-hydration'
import CookieConsent from '@theme/components/shop/CookieConsent'
import FooterBottom from '@theme/components/shop/FooterBottom'
import FooterMain from '@theme/components/shop/FooterMain'
import HeaderMain from '@theme/components/shop/HeaderMain'
import JavascriptRequired from '@theme/components/shop/JavascriptRequired'
import MainMenu from '@theme/components/shop/MainMenu'
import Newsletter from '@theme/components/shop/Newsletter'

export default {
  components: {
    CookieConsent,
    FooterBottom: hydrateWhenVisible(FooterBottom),
    FooterMain: hydrateWhenVisible(FooterMain),
    HeaderMain,
    JavascriptRequired,
    MainMenu: hydrateWhenVisible(MainMenu),
    Newsletter: hydrateWhenVisible(Newsletter),
    Sticky: () => import('@theme/components/utils/Sticky'),
    TopBanner: () => import('@theme/components/content/TopBanner'),
  },
  props: {
    contentRole: {
      type: String,
    },
    showNewsletter: {
      type: Boolean,
      default() {
        return this.$themeSettings.layouts.LayoutBase.showNewsletter
      },
    },
  },
  head() {
    return {
      headAttrs: {
        itemscope: '',
        itemtype: 'http://schema.org/WebSite',
      },
      meta: [
        { itemprop: 'url', content: this.$env.STOREFRONT_URL },
        { itemprop: 'name', content: this.$store.state.globals.meta.seo_short_title },
      ],
    }
  },
}
