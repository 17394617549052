import gql from 'graphql-tag'

export const actions = {
  async LOAD_HEADER_CONFIGURATION() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            configuration_entry_header {
              entry_header_image {
                path
              }
              entry_header_title
              entry_header_text
              entry_header_button_text
              entry_header_button_link
            }
          }
        `,
      })
      return query.data.configuration_entry_header
    } catch (e) {
      console.error(e)
    }
  },
}
